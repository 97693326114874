import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import ReleaseList from '../components/ReleaseList';

const ReleasesPage: FunctionComponent = () => {
    return (
        <Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>Releases by Ballistix</title>
            </Helmet>
            <PageContent>
                <h1>Discography</h1>
                <ReleaseList inline={true} />
            </PageContent>
        </Layout>
    );
}

export default ReleasesPage;